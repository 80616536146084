import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import "../styles/style__projects.css";
import bioguards from "../assets/img/bioguards.png";
import parkty from "../assets/img/parkty.png";
import helpdesk from "../assets/img/helpdesk.png";

const Project_card = ({
  project_title,
  project_year,
  project_category,
  img,
  className,
  shouldFadeIn,
}) => (
  <div
    className={`project__card ${className} ${shouldFadeIn ? "fade-in" : ""}`}
  >
    <div className="project__card__img">
      <img src={img} alt={project_title} />
    </div>
    <div className="project__card__info">
      <h2>{project_year}</h2>
      <h1>{project_title}</h1>
      <h3>{project_category}</h3>
    </div>
  </div>
);
const Project = () => {
  const [shouldFadeInBioguards, setShouldFadeInBioguards] = useState(false);
  const [shouldFadeInParkty, setShouldFadeInParkty] = useState(false);
  const [shouldFadeInHelpdesk, setShouldFadeInHelpdesk] = useState(false);

  const [refBioguards, inViewBioguards] = useInView({ threshold: 0.1 });
  const [refParkty, inViewParkty] = useInView({ threshold: 0.1 });
  const [refHelpdesk, inViewHelpdesk] = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewBioguards) {
      setShouldFadeInBioguards(true);
    }
  }, [inViewBioguards]);

  useEffect(() => {
    if (inViewParkty) {
      setShouldFadeInParkty(true);
    }
  }, [inViewParkty]);
  useEffect(() => {
    if (inViewHelpdesk) {
      setShouldFadeInHelpdesk(true);
    }
  }, [inViewHelpdesk]);

  return (
    <div className="Project__panel">
      <div className="rotating-text">
        <p>Project</p>
        <p>Project</p>
        <p>Project</p>
        <p>Project</p>
        <p>Project</p>
        <p>Project</p>
      </div>
      <div className="projects">
        <div ref={refBioguards}>
          <Project_card
            project_title="Bioguards | Universidad Europea"
            project_year="2020"
            project_category="Universidad Europea"
            img={bioguards}
            className={"normal"}
            shouldFadeIn={shouldFadeInBioguards}
          />
        </div>
        <div ref={refParkty}>
          <Project_card
            project_title="Parkty | Universidad Europea"
            project_year="2021"
            project_category="Universidad Europea"
            img={parkty}
            className={"reverted"}
            shouldFadeIn={shouldFadeInParkty}
          />
        </div>
        <div ref={refHelpdesk}>
          <Project_card
            project_title="Helpdesk | Cincuentenario"
            project_year="2023"
            project_category="Grupo Cincuentenario"
            img={helpdesk}
            className={"normal"}
            shouldFadeIn={shouldFadeInHelpdesk}
          ></Project_card>
        </div>
        <main class="arrow_more" data-form-type="card">
          <a href="javascript:;" class="btn">
            <span class="btn__circle"></span>
            <span class="btn__white-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="icon-arrow-right"
                viewBox="0 0 21 12"
              >
                <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
              </svg>
            </span>
            <span class="btn__text">Discover the project</span>
          </a>
        </main>
      </div>
    </div>
  );
};
export default Project;

import React, { useState, useRef } from "react";
import "../styles/style__code.css";

// Definición de un componente funcional llamado MyComponent
function MyComponent() {
  const [inputValue, setInputValue] = useState(
    ">>Has click aqui para empezar a programar conmigo!"
  );
  const handleFocus = () => {
    if (inputValue === ">>Has click aqui para empezar a programar conmigo!") {
      setInputValue(">>");
    }
  };
  const textAreaRef = useRef(null);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const lines = inputValue.split("\n");
      const currentLine = lines[lines.length - 1].replace(/\s/g, "");
      if (currentLine.trim() === ">>Trabajo.getExperienciaLaboral()") {
        const newText =
          "\n\n>> Experiencia Laboral\n--------------------------------\nCompañia: BLANCH INTERNATIONAL\nCargo: I+A\n\nDuración: 5 meses\nDescripción:\n--------------------------------\n\n>> Experiencia Laboral\n--------------------------------\nCompañia: FORD CINCUENTENARIO\nCargo: Desarrolladora/Soporte Técnico\n\nDuración: 6 meses\nDescripción:\n--------------------------------\n\n";

        const originalLength = inputValue.length;

        setInputValue(inputValue + newText);

        // Esperar 3 segundos antes de escribir "Hola"
        setTimeout(() => {
          // Obtener el valor actual del campo de entrada
          const updatedInputValue = inputValue + newText + ">>";

          // Actualizar el valor del campo de entrada
          setInputValue(updatedInputValue);

          // Establecer la selección en el lugar correcto después de "Hola"
          if (textAreaRef.current) {
            const startPosition = originalLength;
            const endPosition = updatedInputValue.length;

            textAreaRef.current.selectionStart = startPosition;
            textAreaRef.current.selectionEnd = endPosition;
          }
        }, 200);
      } else if (currentLine.trim() === ">>Estudio.getTitulo()") {
        const newText =
          "\n\n>> Estudios:\n--------------------------------\nBachiller: Colegio de La Salle\nBachiller: Ciencias y Letras\n\nUniversidad: Universidad Europea\nTitulacion: Ingeniería Informática\n--------------------------------\n\n";
        const originalLength = inputValue.length;

        setInputValue(inputValue + newText);
        setTimeout(() => {
          // Obtener el valor actual del campo de entrada
          const updatedInputValue = inputValue + newText + ">>";

          // Actualizar el valor del campo de entrada
          setInputValue(updatedInputValue);

          // Establecer la selección en el lugar correcto después de "Hola"
          if (textAreaRef.current) {
            const startPosition = originalLength;
            const endPosition = updatedInputValue.length;

            textAreaRef.current.selectionStart = startPosition;
            textAreaRef.current.selectionEnd = endPosition;
          }
        }, 200);
      } else {
        const newText = `\n>> Error de compilación\n`;
        const originalLength = inputValue.length;

        setInputValue(inputValue + newText);
        setTimeout(() => {
          // Obtener el valor actual del campo de entrada
          const updatedInputValue = inputValue + newText + ">>";

          // Actualizar el valor del campo de entrada
          setInputValue(updatedInputValue);

          // Establecer la selección en el lugar correcto después de "Hola"
          if (textAreaRef.current) {
            const startPosition = originalLength;
            const endPosition = updatedInputValue.length;

            textAreaRef.current.selectionStart = startPosition;
            textAreaRef.current.selectionEnd = endPosition;
          }
        }, 200);
      }
    }
  };
  const handleBackspace = (inputValue, setInputValue) => {
    // Dividir el texto en líneas separadas
    const lines = inputValue.split("\n");
    console.log("luneas", lines);
    // Obtener la última línea
    const lastLine = lines[lines.length - 1];
    console.log("ultima linea", lastLine);
    // Verificar si la última línea tiene al menos un carácter
    if (lastLine.length > 0) {
      // Eliminar el último carácter de la última línea
      const newLastLine = lastLine.slice(0, -1);
      console.log("ultima linea cirtada", newLastLine);
      // Reconstruir el texto con la última línea modificada
      const newInputValue = lines.slice(0, -1).join("\n") + newLastLine;

      // Actualizar el estado del campo de entrada con el nuevo valor
      setInputValue(newInputValue);
    }
  };

  return (
    <div className="experience__work">
      <div className="code__logs">
        <div className="folder">
          <i class="bi bi-0-square-fill"></i>
          <p className="lowercase">Work.js</p>
          <p className="uppercase">U</p>
        </div>
        <div className="code">
          <code>
            <span className="class">public class</span>
            <span className="name"> Persona</span>{" "}
            <span className="brackets">{"{"}</span>
            <br></br>
            <span className="class">&nbsp;public string</span>
            <span className="brackets"> nombre;</span>
            <br></br>
            <span className="class">&nbsp;public number</span>
            <span className="brackets"> edad;</span>
            <br></br>
            <span className="class">&nbsp;public string</span>
            <span className="brackets"> sexo;</span>
            <br></br>
            <span className="brackets"> {"}"}</span>{" "}
          </code>
          <code>
            <span className="class">public class</span>
            <span className="name"> Estudio</span>{" "}
            <span className="brackets">{"{"}</span>
            <br></br>
            <span className="class">&nbsp;public string</span>
            <span className="brackets"> titulo;</span>
            <br></br>
            <span className="class">&nbsp;public string </span>
            <span className="method">getTitulo()</span>
            <span className="brackets">{"{"}</span>
            <br></br>
            <span className="return">&nbsp;&nbsp;return </span>
            <span className="class">this.</span>
            <span className="return-variable ">titulo</span>
            <br></br>
            <span className="brackets">&nbsp;{"}"}</span> <br></br>
            <span className="brackets">{"}"}</span>
          </code>

          <code>
            <span className="class">public class</span>
            <span className="name"> Trabajo</span>{" "}
            <span className="brackets">{"{"}</span>
            <br></br>
            <span className="class">&nbsp;public string</span>
            <span className="brackets"> cargo;</span>
            <br></br>
            <span className="class">&nbsp;public string</span>
            <span className="brackets"> experiencia;</span>
            <br></br>
            <span className="class">&nbsp;public string </span>
            <span className="method">getExperienciaLaboral()</span>
            <span className="brackets">{"{"}</span>
            <br></br>
            <span className="return">&nbsp;&nbsp;return </span>
            <span className="class">this.</span>
            <span className="return-variable ">cargo </span>
            <span className="brackets">{"+"}</span>
            <span className="class"> this.</span>
            <span className="return-variable ">experiencia </span>
            <br></br>
            <span className="brackets">&nbsp;{"}"}</span> <br></br>
            <span className="brackets"> {"}"}</span>
          </code>
        </div>
      </div>
      <div className="code__console">
        <div className="console__buttons"></div>
        <div className="console__response">
          <textarea
            ref={textAreaRef}
            placeholder=">>Escribe alguna de las siguientes opciones:\n Trabajo.getExperienciaLaboral()"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            rows={10}
            onFocus={handleFocus}
            cols={50}
            spellCheck={false} // Desactiva la corrección ortográfica
          />

          <p className="response"></p>
        </div>
      </div>
    </div>
  );
}

export default MyComponent;

import React from "react";
import "../styles/style__estudios.css";
import { useState } from "react";

const Portada = ({ className }) => (
  <div className={`education__portada ${className}`}>
    <h1 className="emptyH1">Educacion</h1>
    <h1 className="filledH1">Educacion</h1>
    <h1 className="emptyH1">Educacion</h1>
    <h1 className="filledH1">Educacion</h1>
    <h1 className="emptyH1">Educacion</h1>
    <h1 className="filledH1">Educacion</h1>
    <h1 className="emptyH1">Educacion</h1>
    <h1 className="filledH1">Educacion</h1>
    <h1 className="emptyH1">Educacion</h1>
    <h1 className="filledH1">Educacion</h1>
  </div>
);
const Clases = ({ anio, escuela, titulacion, pais, imagen }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setCursorPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <div
      className="clases"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="clases__anio">{anio}</div>
      <div className="clases__institucion">
        <h3 className="clases__escuela">{escuela}</h3>
        <h3 className="clases__titulacion">{titulacion}</h3>
      </div>
      <div className="clases__pais">{pais}</div>
      {isHovering && (
        <img
          src={imagen}
          alt="Logo"
          className="logo"
          style={{
            position: "fixed",
            left: cursorPosition.x,
            top: cursorPosition.y,
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
          }}
        />
      )}
    </div>
  );
};

const Education = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e) => {
    setCursorPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <div className="education">
      <div className="sparkles"></div>
      <div className="portada">
        <Portada className="movingLeft" />
        <Portada className="movingRight" />
        <Portada className="movingLeft" />
      </div>
      <div className="education__titulo">
        <h2>Study</h2>
      </div>
      <div className="education__clases">
        <Clases
          anio={2018}
          escuela={"Colegio de La Salle"}
          titulacion={"Bachiller en Ciencias y Letras"}
          pais={"Panamá"}
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          isHovering={isHovering}
          cursorPosition={cursorPosition}
          imagen={
            "https://seeklogo.com/images/C/Colegio_La_Salle-logo-B07F989C68-seeklogo.com.png"
          }
        />

        <Clases
          anio={2023}
          escuela={"Universidad Europea de Madrid"}
          titulacion={"Ingenieria Informática"}
          pais={"España"}
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          isHovering={isHovering}
          cursorPosition={cursorPosition}
          imagen={
            "https://scontent.fpac1-3.fna.fbcdn.net/v/t31.18172-8/18491851_10155315335009841_7270462036017241954_o.png?_nc_cat=101&ccb=1-7&_nc_sid=7a1959&_nc_ohc=9wSH-uEHluQAX9Kr9Hh&_nc_ht=scontent.fpac1-3.fna&oh=00_AfDmknRVfnSe4-LFqJ0gBwBgm4oxA_nyP0-atvjOsSohiQ&oe=65F4715C"
          }
        />
      </div>
    </div>
  );
};
export default Education;

import React from "react";
import "../styles/style__postada.css";

const PortadaBackground = () => {
  return (
    <div className="portada__container">
      <div className="portada__background">
        <div className="space">
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
        </div>
      </div>
      <div className="portada__data">
        <div className="portada__middle">
          <h1>
            Full Stack<br></br>Developer
          </h1>
          <h2>Adilem Dobras</h2>
        </div>
        <div className="portada_bottom">
          <p>setScrollToContinue(event) </p>
          <div class="scroll-downs">
            <div class="mousey">
              <div class="scroller"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortadaBackground;

import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion"; // Importa motion desde framer-motion
import "./App.css";
import Estudios from "./componentes/estudios.js";
import Skills from "./componentes/skills.js";
import Projects from "./componentes/projects.js";
import Portada from "./componentes/portada.js";
import Menu from "./componentes/menu.js";
import Work from "./componentes/work.js";

function App() {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      // Verifica que el contenedor exista
      if (containerRef.current) {
        event.preventDefault(); // Previene el scroll horizontal predeterminado
        // Ajusta el scroll horizontal basado en el movimiento de la rueda del mouse
        containerRef.current.scrollLeft += event.deltaY;
      }
    };

    // Agrega el listener al evento wheel
    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false });
    }

    // Función de limpieza para remover el listener
    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []); // Dependencias vacías para ejecutar solo al montar y desmontar

  // Define las variantes de animación
  const animationVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className="App" ref={containerRef}>
      <Menu className="Menu" />
      <div className="container">
        <motion.div // Agrega motion.div alrededor de tu componente
          className="page_projects"
          variants={animationVariants} // Aplica las variantes de animación
          initial="hidden" // Define el estado inicial de la animación
          animate="visible" // Define el estado final de la animación
          transition={{ duration: 0.5 }} // Define la duración de la animación
        >
          <Portada className="Portada" />
        </motion.div>
        <div id="seccion_projects" className="page_projects">
          <Projects className="Projects" />
        </div>
        <div id="seccion_work" className="page_projects">
          <Work className="Work" />
        </div>
        <div className="page_estudios">
          <Estudios className="Estudios" />
        </div>
        <div className="page_skill">
          <Skills className="Skills" />
        </div>
      </div>
    </div>
  );
}

export default App;

import React, { useEffect, useRef } from "react";
import TagCloud from "TagCloud";
import "../styles/style__skills.css";

const Skills = () => {
  const tagCloudContainer = useRef(null); // Create a ref for the container

  useEffect(() => {
    console.log("Skills component mounted");

    const myTags = [
      "JavaScript",
      "CSS",
      "HTML",
      "C",
      "C++",
      "React",
      "Python",
      "Java",
      "git",
      "django",
      "Node.js",
      "OpenCV",
      "GCP",
      "MySQL",
      "jQuery",
    ];

    if (tagCloudContainer.current) {
      TagCloud(tagCloudContainer.current, myTags, {
        // Opciones de configuración aquí
        radius: 250,
        maxSpeed: "normal",
        initSpeed: "normal",
        direction: 135,
        keep: true,
      });
    }

    // Clean up the tag cloud to prevent duplication when the component unmounts or re-renders
    return () => {
      console.log("Cleaning up tag cloud");
      if (tagCloudContainer.current) {
        // Assumes TagCloud provides a method to properly clean up the cloud, replace 'destroy' with the correct method name if available
        // TagCloud.destroy(tagCloudContainer.current);
        // If no cleanup method is provided by TagCloud, manually clear the container
        tagCloudContainer.current.innerHTML = "";
      }
    };
  }, []); // Empty dependency array to ensure this effect runs only once

  return (
    <div className="tagcloud">
      <span ref={tagCloudContainer} className="content"></span>
      <p>
        ¡Hola! Bienvenido al universo digital. Soy un experto en múltiples
        lenguajes de programación como Python, JavaScript y C++. Estoy aquí para
        ayudarte con tus proyectos y desafíos de desarrollo de software. Soy
        como un políglota de la tecnología, capaz de comunicarme fluidamente en
        diferentes idiomas de programación. ¿Estás listo para explorar el
        fascinante mundo del código juntos?
      </p>
    </div>
  );
};

export default Skills;

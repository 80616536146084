import React, { useState } from "react";
import "../styles/style__menu.css";
import foto from "../assets/img/portada_imagen.jpeg";
import faw1 from "../assets/img/FawAbout.png";
import faw2 from "../assets/img/FawPortada.png";
import helpdesk1 from "../assets/img/HelpdeskLogin.png";
import helpdesk2 from "../assets/img/HelpdeskTable.png";

function Menu() {
  const [showMaxMenu, setShowMaxMenu] = useState(false);
  const [isCrossed, setIsCrossed] = useState(false);

  const toggleMaxMenu = () => {
    setShowMaxMenu(!showMaxMenu);
    setIsCrossed(!isCrossed);
  };
  return (
    <div className="Menu">
      <div className="Menu_mini">
        <div className="Menu__access">
          <button
            className={`sidebar__menu-line ${isCrossed ? "crossed" : ""}`}
            onClick={toggleMaxMenu}
          ></button>
        </div>
        <div className="Menu_me">
          <img alt="imagen" src={foto}></img>
        </div>
        <div className="Menu_socialmedia">
          <a
            href="https://www.instagram.com/adilem_dobras"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-instagram"></i>
          </a>{" "}
          <a
            href="https://www.linkedin.com/in/adilem-dobras-29555a249/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-linkedin"></i>
          </a>{" "}
          <a
            href="https://github.com/adobrasca512"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-github"></i>
          </a>
        </div>
      </div>
      <div className={`Menu_max ${showMaxMenu ? "show" : ""}`}>
        <div>
          <p className="menu-subtitle"> Elije un Proyecto</p>
          <ul>
            <li>
              <p>Helpdesk Portfolio</p> <img src={helpdesk1}></img>
              <img src={helpdesk2}></img>
            </li>
            <li>
              <p>Faw Trucks</p> <img src={faw1}></img>
              <img src={faw2}></img>
            </li>
          </ul>
        </div>
      </div>{" "}
    </div>
  );
}

export default Menu;
